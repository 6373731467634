import React from 'react'
import "./ContactForm.css"

const ContactForm = () => {
  return (
    <div className='contact-container'>
      <form action="https://api.web3forms.com/submit" method="POST" className='contact-left'>
        <div className='contact-left-title'>
          <h2 style={{fontSize:30,color:'#fd567c'}}>
            Get in Touch
          </h2>
          
        </div>
        <input type="hidden" name="access_key" value="b87e4e01-2a63-4dbd-b302-adfb2e9a2b17"></input>
        <input type='text' name='name' placeholder='Enter your Name' className='contact-inputs' required/>
        <input type='email' name='email' placeholder='Enter your Email Address' className='contact-inputs' required/>
        <textarea name='message' placeholder='Write a Message' className='contact-inputs' required></textarea>
        <button type='submit'>Submit</button>
      </form>
    </div>
  )
}

export default ContactForm