import React from 'react'
// import Logo from './logo.png'
// import Deliversg from './deliversg-logo.png'
import Logo from "./logonew.png"
import './Navbar.css';

const Navbar = () => {
  return (
    <div className='navbar'>
       <img style={{width:155,height:88}} src={Logo} alt="" className='logo'/>
       {/* <button className="red-btn">Contact</button> */}
{/*        
       <figcaption className='sub'>Powered By<img src={Deliversg} className='logo1' alt=' '/></figcaption> */}
       
    </div>
  )
}
export default Navbar;
