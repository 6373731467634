import Home from "./Home";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ContactForm from "./Pages/ContactForm";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import { Route, Routes } from "react-router-dom";
import Form from "./Pages/Datadeletion/Datadeletion";

function App(){
  return(
  <div className="app">
  <Navbar/>
  <Routes>
    <Route path="/" element={<Home/>} Component={Home}></Route>
    <Route path='/contact' element={<ContactForm/>} Component={ContactForm}></Route>
    <Route path="/privacypolicy" element={<PrivacyPolicy/>} Component={PrivacyPolicy}></Route>
    <Route path="/datadeletion" element={<Form/>} Component={Form}></Route>
  </Routes>
  <Footer/>

  </div>
  )
}
export default App