import React from "react";
import logo1 from "./logo1.png";
import "./Hero1.css";
import Announce from "./announce.png";
export const Hero1 = () => {
  return (
    <div className="hero-con">
      <div style={{ position: "relative" }}>
        <svg
          className="close-mark"
          id="close"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="red"
          class="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 20,
            borderWidth: 0,
          }}
        >
          <img style={{ width: 56.3, height: 45 }} src={Announce} alt=" " />
          <p className="happy">We Are Happy To Announce</p>
        </div>
        <br></br>
        <div
          style={{
            display: "flex",
            borderWidth: 0,
            gap: 0,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p className="busybee">Busybee is acquired by</p>{" "}
          <img src={logo1} alt=" " style={{ marginTop: "10px" }} />
        </div>
      </div>
    </div>
  );
};
